// Imports: Packages, Components, and Contexts
import {useEffect, useState} from "react";
import Issuer from "../components/Issuer";
import { useAccountProvider } from "../contexts/account";
import * as wasm from '../../issuer/pkg/issuer';
import { Card } from "antd";
import {useGlobalProvider} from "../contexts/global.js";

// Main App Component
function IssuerApp() {
  // State & Context: Account and Web Worker
  const { account } = useAccountProvider();
  const { global, setGlobal } = useGlobalProvider();
  const [signature, setSignature] = useState(null);
  const [dobfield, setDOBField] = useState(null);
  const [subjectfield, setSubjectField] = useState(null);
  const [nationalityfield, setNationalityField] = useState(null);
  const [expiryfield, setExpiryField] = useState(null);
  const [isSignatureGenerated, setIsSignatureGenerated] = useState(false);

  function resetState() {
    setNationalityField(null);
    setExpiryField(null);
    setSubjectField(null);
    setDOBField(null);
    setSignature(null);
    setIsSignatureGenerated(false);
    setGlobal({});
  }

  useEffect(() => {
    resetState();
    const privateKey = account ? account.privateKey().to_string() : "";
    const viewKey = account ? account.viewKey().to_string() : "";
    const address = account ? account.address().to_string() : "";
    if (privateKey) setGlobal({ privateKey, viewKey, issuer: address, r1: address });
  }, [account])

  // Handler: Generate signature using Issuer
  const handleIssuerGenerateSignature = (values) => {
    if (!account) {
      alert('Please select issuer account');
      return;
    }

    // Construct message data

    // debugger

    const subject = values.subject;
    const hash = values.hash;
    const dob = values.dob.format('YYYYMMDD');
    const nationality = values.nationality;
    const expiry = values.expiry?.format('YYYYMMDD');

    setNationalityField(nationality);
    setExpiryField(expiry);
    setSubjectField(subject);
    setDOBField(dob);

    const signInboundMessage = new wasm.SignInboundMessage(subject, dob, nationality, expiry);

    console.log(signInboundMessage);

    const {signature} = wasm.sign_message(account.privateKey().to_string(), signInboundMessage, hash);

    setSignature(signature);

    setGlobal({...global, ...{
        subject,
        hash,
        signature,
        dob: wasm.get_field_from_value(dob),
        nationality: wasm.get_field_from_value(nationality),
        expiry: wasm.get_field_from_value(expiry),
        r0: signature,
      }});


    // Mark signature as generated
    setIsSignatureGenerated(true);
  };

  // Main Render
  return (
    <>
      {/* Main Content */}
      <Card title="Issuer" style={{ margin: '0px 10px 0px 10px' }}>
        <Issuer
          handleIssue={handleIssuerGenerateSignature}
          isSignatureGenerated={isSignatureGenerated}
          generatedSignature={signature}
          dobfield={dobfield}
          setDOBField={setDOBField}
          subjectfield={subjectfield}
          nationalityfield={nationalityfield}
          expiryfield={expiryfield}
          issuer={account}
          global={global}
        />
      </Card>
    </>
  );
}

// Exporting App Component
export default IssuerApp;

