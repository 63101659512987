export const SIGVERIFY_PROGRAM_KECCAK_256 = `
program offchain_verifier1.aleo;

struct Message:
    issuer as address;
    subject as address;
    dob as field;
    nationality as field;
    expiry as field;


closure get_msg_hash:
    input r0 as Message;
    hash.keccak256 r0 into r1 as field;
    output r1 as field;


closure signature_verification:
    input r0 as field;
    input r1 as signature;
    input r2 as address;
    sign.verify r1 r2 r0 into r3;
    output r3 as boolean;


function verify:
    input r0 as signature.private;
    input r1 as address.private;
    input r2 as Message.private;
    call get_msg_hash r2 into r3;
    call signature_verification r3 r0 r1 into r4;
    output r4 as boolean.public;
`;

export const SIGVERIFY_PROGRAM_SHA3_256 = `
program offchain_verifier2.aleo;

struct Message:
    issuer as address;
    subject as address;
    dob as field;
    nationality as field;
    expiry as field;


closure get_msg_hash:
    input r0 as Message;
    hash.sha3_256 r0 into r1 as field;
    output r1 as field;


closure signature_verification:
    input r0 as field;
    input r1 as signature;
    input r2 as address;
    sign.verify r1 r2 r0 into r3;
    output r3 as boolean;


function verify:
    input r0 as signature.private;
    input r1 as address.private;
    input r2 as Message.private;
    call get_msg_hash r2 into r3;
    call signature_verification r3 r0 r1 into r4;
    output r4 as boolean.public;
`;

export const SIGVERIFY_PROGRAM_BHP1024 = `
program offchain_verifier3.aleo;

struct Message:
    issuer as address;
    subject as address;
    dob as field;
    nationality as field;
    expiry as field;


closure get_msg_hash:
    input r0 as Message;
    hash.bhp1024 r0 into r1 as field;
    output r1 as field;


closure signature_verification:
    input r0 as field;
    input r1 as signature;
    input r2 as address;
    sign.verify r1 r2 r0 into r3;
    output r3 as boolean;


function verify:
    input r0 as signature.private;
    input r1 as address.private;
    input r2 as Message.private;
    call get_msg_hash r2 into r3;
    call signature_verification r3 r0 r1 into r4;
    output r4 as boolean.public;
`;

export const SIGVERIFY_PROGRAM_POSEIDON2 = `
program offchain_verifier4.aleo;

struct Message:
    issuer as address;
    subject as address;
    dob as field;
    nationality as field;
    expiry as field;


closure get_msg_hash:
    input r0 as Message;
    hash.psd2 r0 into r1 as field;
    output r1 as field;


closure signature_verification:
    input r0 as field;
    input r1 as signature;
    input r2 as address;
    sign.verify r1 r2 r0 into r3;
    output r3 as boolean;


function verify:
    input r0 as signature.private;
    input r1 as address.private;
    input r2 as Message.private;
    call get_msg_hash r2 into r3;
    call signature_verification r3 r0 r1 into r4;
    output r4 as boolean.public;
`;
